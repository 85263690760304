<template lang="pug">
.main-wrapper.grupos-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Grupos de Acesso</b>

		Dialog.dialogApagar(header='Remover Grupo' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o endpoint <b>{{ dialogApagar_data.nm_grupo }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove()')

		Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid
				.p-col-12
					label.form-label Grupo:
					.p-inputgroup
						InputText(
							placeholder='Descrição do grupo'
							@keyup.enter.native='applyFilters()'
							v-model='filters.nm_grupo'
							@input='checkEmptyField("nm_grupo")'
						)
						Button(icon='jam jam-search' @click='applyFilters()')
		.ta-right.mt-4.mb-4
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/grupos/salvar/0/')")

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
						Panel.panel-list.ta-center(:header="props.data.nm_grupo" style='position: relative')
							.ta-left
								p <b>Descrição:</b> {{ props.data.nm_grupo }}
								p <b>Nível:</b> {{ props.data.nr_nivel }}

			Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			Panel.datatable(header='Lista de Grupos')
				DataTable(:value="list")
					Column(headerStyle='width: 40%;' field='nm_grupo' header='Descrição')
					Column(headerStyle='width: 40%;')
						template(#header) Menus
						template(#body='props')
							.ta-center.p-grid(v-if="(props.data.menus).length > 0" style="height: fit-content; padding: 15px; line-height: 35px;")
								div(v-for="menu in props.data.menus" :key='menu.id_menu')
									t.grupos-listar.menu-tags.p-col-3
										b {{ menu.nome_menu }}
							.ta-center(v-else)
								p Sem permissão de menus.
					Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_nivel' header='Nível')
					Column(headerStyle='width: 10%; text-align: center')
						template(#header) Ações
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="$router.push(`/grupos/salvar/${ props.data.id }/`)"
								)
								Button.p-button-raised.p-button-rounded.p-button-danger(
									v-tooltip.top="'Apagar'"
									icon="jam jam-minus-circle"
									@click="dialogApagar = true; dialogApagar_data = props.data"
								)
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
							
		
</template>

<style lang="scss">
	.grupos-listar {
		.menu-tags {
			padding: 5px;
			border: 1px solid #6666;
			margin-right: 5px;
			background-color: #6666;
			border-radius: 15px;
			vertical-align: middle;
		}
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				&.ex  {  background-color: #e4f8e1; }
				&.a  { background-color: #faf3dd; }
			}
		}
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Dialog from 'primevue/dialog'
	import InputText from 'primevue/inputtext'
	import ProgressSpinner from 'primevue/progressspinner'

	import { Grupos } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	export default {
		created () { this.applyFilters() },
		components: { ProgressBar, DataView, Panel, Paginator, DataTable,
			Column, Button, Tooltip, Dialog, ProgressSpinner, InputText },
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingApagar: false,
				dialogApagar: false,
				dialogApagar_data: {},
				filters: {
					nm_grupo: '',
					ds_endpoint: '',
				},
				paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				}
			}
		},
		methods: {
			getList (params) {
				this.waiting = true
				return Grupos.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count
						this.list = response.data.results
					}
					this.waiting = false
					return true
				})
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page, menus: true }
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
				this.getList(params)
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			remove () {
				this.waitingApagar = true
				Grupos.remove(this.dialogApagar_data.id).then(response => {
					this.waitingApagar = false
					if (([200, 201, 204]).includes(response.status)) {
						this.$toast.info('Grupo removido com sucesso', { duration: 3000 })
						this.dialogApagar = false
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			}
		}
	}
</script>